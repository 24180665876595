<template>
  <div>
    <template v-if="newCookie">
      <client-only>
        <cookie-alert-v-2 />
      </client-only>
    </template>
    <template v-else>
      <cookie-alert />
    </template>
    <scroll-to-top v-if="!hideScrollTop" />
    <cart-trigger v-if="!hideCartTrigger" />
  </div>
</template>

<script>
import cookieAlert from 'marketplace-front-core/components/layout/CookieAlert'
import ScrollToTop from 'marketplace-front-core/components/layout/ScrollToTop'
import CartTrigger from 'marketplace-front-core/components/layout/CartTrigger'

export default {
  components: {
    cookieAlert,
    cookieAlertV2: () => { if (process.env.cookieAlertV2 && process.env.cookieAlertV2 === 'true') { return import('marketplace-front-core/components/layout/CookieAlertV2') } },
    ScrollToTop,
    CartTrigger
  },
  props: {
    hideScrollTop: {
      type: Boolean,
      default: false
    },
    hideCartTrigger: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    newCookie () {
      return process.env.cookieAlertV2 && process.env.cookieAlertV2 === 'true'
    }
  },
  mounted () {
    if (process.env.facebookPixedId && this.$fb && this.$fb.fbq && this.user) {
      const check = ['ROLE_SELLER_OWNER', 'ROLE_SELLER'].filter(val =>
        this.user.roles.includes(val)
      )

      if (check.length > 0) {
        this.$fb.disable()
      }
    }

    setTimeout(() => {
      const url = process.env.webSocketUrl
      if (url) {
        this.initWebSocket()
      }
    }, 500)
  }

}
</script>
